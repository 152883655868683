<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="isUpdate?$t('users.driver.update_btn'):$t('users.driver.new_btn')"
        :paths="[{title:$t('users.driver.title'),link:'users-drivers'}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('users.driver.info') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.name"
                          :label="$t('users.driver.table.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                          v-model="form.delivery_type"
                          :label="$t('users.driver.table.delivery_type')"
                          :placeholder="$t('users.driver.table.delivery_type')"
                          variant="disable"
                          :items="getVehicleTypes"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>

                    <b-col cols='12' md='4'>
                      <wameed-dropdown
                        v-model='form.platform_branch'
                        :label="$t('branches.branch')"
                        :placeholder="$t('branches.branch')"
                        variant='disable'
                        :items='getBranches'
                        class='wameed_dropdown'
                        title='name'
                        rules='required'
                        :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.phone"
                          field-classes="w-100"
                          rules="required"
                          :label="$t('form.phone.label')"
                          :placeholder="$t('form.text.placeholder')"
                          type="number"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.platform_commission"
                          field-classes="w-100"
                          rules="required|max_value:100"
                          :label="$t('form.delivery_platform_commission.label')"
                          :placeholder="$t('form.text.placeholder')"

                          is-append
                          append-text="%"
                          type="number"
                      />
                    </b-col>


                  </div>


                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="isUpdate?$t('users.driver.update_btn'):$t('users.driver.new_btn')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {WameedBtn, WameedDropdown, WameedForm, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      form: {
        name: '',
        delivery_type: '',
        platform_commission: '',
        platform_branch: '',
        phone: '',
      },

    };
  },
  computed: {
    ...mapGetters({
      getVehicleTypes: 'admin/users/getVehicleTypes',
      getBranches: 'admin/users/getBranches',
      userDetail: 'admin/users/getDetail',
    }),
    isUpdate() {
      return (this.$route.name === 'users-drivers-update' && this.$route.params.id);
    }
  },
  async beforeCreate() {
    await this.$store.dispatch('admin/users/getBranches');
    await this.$store.dispatch('admin/users/getVehicleTypes');
    if (this.$route.name === 'users-drivers-update' && this.$route.params.id) {
      this.$store.dispatch("admin/users/simpleDetail", {id: this.$route.params.id, type: 'drivers'}).then(() => {

        this.form = this.userDetail;
        this.form.delivery_type = this.getVehicleTypes.find((item) => item.id === this.userDetail.vehicle_type_id);
        this.form.platform_branch = this.getBranches.find((item) => item.id === this.userDetail.platform_branch_id);
      });

    } else {
      await this.$store.dispatch("admin/users/unsetDetail");
    }
  },


  methods: {


    goBack() {
      this.$router.push({name: 'users-drivers'});
    },

    submit() {

      this.form.platform_branch_id = this.form.platform_branch.id;
      this.form.vehicle_type_id = this.form.delivery_type.id;
      this.form.type = 'drivers'

      if (this.$route.name === 'users-drivers-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('admin/users/update', this.form);
      } else {
        this.$store.dispatch('admin/users/create', this.form);
      }

    },
  }
  ,
}
;
</script>
